import classNames from 'classnames';
import { Icon, IconsEnum } from 'components/Icons';
import { AnchorLink } from 'components/anchorLink';
import { RichText } from 'components/richText';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import styles from './BannerInformation.module.scss';

export type BannerInformationProps = {
  type?: 'error' | 'success' | 'informative' | 'warning';
  text: string;
  visible: boolean;
  icon?: IconsEnum;
  isDismissible?: boolean;
  actionButton?: {
    element: React.ReactNode;
    onClick: () => void;
  };
  redirectButton?: {
    element: React.ReactNode;
    url: string;
  };
  actionOnDismiss?: () => void;
  layoutType?: 'big' | 'small' | 'onlylabel';
  className?: string;
};
export const DynamicBannerInformation = dynamic(
  () => import('components/bannerInformation').then((mod) => mod.BannerInformation),
  {
    ssr: false,
  }
);

export const BannerInformation = ({
  type = 'informative',
  text,
  icon,
  visible,
  className,
  actionButton,
  isDismissible,
  redirectButton,
  actionOnDismiss,
  layoutType = 'big',
}: BannerInformationProps) => {
  const typeIconDictionary: { [key in typeof type]: IconsEnum } = {
    error: IconsEnum.EXCLAMATION_CIRCLE,
    success: IconsEnum.SUCCESS,
    informative: icon ?? IconsEnum.EXCLAMATION_CIRCLE,
    warning: IconsEnum.EXCLAMATION_CIRCLE,
  };

  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  /*{text}*/

  return (
    <div
      className={classNames(styles.container, styles[type], styles[layoutType], className, {
        [styles.visible]: isVisible,
      })}
    >
      <div className={styles.item}>
        {layoutType === 'onlylabel' ? (
          <p className={`${styles.paragraph} ${styles[type]}`}>
            {icon && (
              <span className={styles.iconContainer}>
                <Icon iconId={icon} className={styles.iconLeft} />
              </span>
            )}
            <RichText containerTag="span" containerClassName={styles.richtext}>
              {text}
            </RichText>
          </p>
        ) : (
          <p className={styles.paragraph}>
            <span className={`${styles.iconContainer}`}>
              <Icon iconId={typeIconDictionary[type]} className={`${styles.iconLeft}`} />
            </span>
            <RichText containerTag="span" containerClassName={styles.richtext}>
              {text}
            </RichText>
          </p>
        )}

        {(actionButton || redirectButton || isDismissible) && (
          <div className={styles.trailingIconContainer}>
            {actionButton && (
              <button onClick={actionButton.onClick} className={styles.cta} type="button">
                {actionButton.element}
              </button>
            )}
            {redirectButton && (
              <AnchorLink href={redirectButton.url} className={styles.cta}>
                {redirectButton.element}
              </AnchorLink>
            )}
            {isDismissible && (
              <button
                type="button"
                onClick={() => {
                  if (!!actionOnDismiss) {
                    return actionOnDismiss();
                  }
                  setIsVisible(false);
                }}
                className={styles.dismissButton}
              >
                <Icon iconId={IconsEnum.CLOSE} className={styles.closeIcon} />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
