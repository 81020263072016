import { Spinner } from 'components/spinner';
import React, { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import styles from './Button.module.scss';

export type ButtonProps = {
  disabled?: boolean | string;
  visualizationType?: 'primary' | 'secondary' | 'tertiary' | 'sideMenuUserPrimary' | 'sideMenuUserTertiary';
  size?: 'large' | 'medium' | 'small';
  children: React.ReactNode;
  isLoading?: boolean;
  className?: string;
  fullWidth?: boolean;
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = ({
  disabled = false,
  visualizationType,
  size = 'large',
  children,
  isLoading,
  className,
  fullWidth = false,
  ...defaultProps
}: ButtonProps) => {
  return (
    <button
      {...defaultProps}
      disabled={disabled}
      className={` 
        ${fullWidth ? styles.fullWidth : ''}
        ${visualizationType ? styles[visualizationType] : ''} 
        ${styles.buttonContainer} 
        ${isLoading && styles.disabled}
        ${size ? styles[size] : ''}
        ${disabled ? styles.disabled : ''}
        ${className ? className : ''}
      `}
    >
      <div className={`${styles.childrenContainer} ${isLoading ? styles.loading : ''}`}>{children}</div>
      {isLoading && <Spinner className={styles.spinner} />}
    </button>
  );
};
