import React from 'react';
import classNames from 'classnames';
import { parse } from 'node-html-parser';
import styles from './RichText.module.scss';

export type RichTextProps = {
  children?: string | undefined;
  containerTag?: string;
  containerClassName?: string;
};

const htmlEntities: Record<string, string> = {
  '&Aacute;': 'Á',
  '&aacute;': 'á',
  '&Abreve;': 'Ă',
  '&abreve;': 'ă',
  '&Acirc;': 'Â',
  '&acirc;': 'â',
  '&acute;': '´',
  '&AElig;': 'Æ',
  '&aelig;': 'æ',
  '&Agrave;': 'À',
  '&agrave;': 'à',
  '&Alpha;': 'Α',
  '&alpha;': 'α',
  '&Amacr;': 'Ā',
  '&amacr;': 'ā',
  '&amp;': '&',
  '&and;': '∧',
  '&ang;': '∠',
  '&Aogon;': 'Ą',
  '&aogon;': 'ą',
  '&apos;': "'",
  '&Aring;': 'Å',
  '&aring;': 'å',
  '&asymp;': '≈',
  '&Atilde;': 'Ã',
  '&atilde;': 'ã',
  '&Auml;': 'Ä',
  '&auml;': 'ä',
  '&bdquo;': '„',
  '&Beta;': 'Β',
  '&beta;': 'β',
  '&brvbar;': '¦',
  '&bull;': '•',
  '&Cacute;': 'Ć',
  '&cacute;': 'ć',
  '&cap;': '∩',
  '&Ccaron;': 'Č',
  '&ccaron;': 'č',
  '&Ccedil;': 'Ç',
  '&ccedil;': 'ç',
  '&Ccirc;': 'Ĉ',
  '&ccirc;': 'ĉ',
  '&Cdot;': 'Ċ',
  '&cdot;': 'ċ',
  '&cedil;': '¸',
  '&cent;': '¢',
  '&Chi;': 'Χ',
  '&chi;': 'χ',
  '&circ;': 'ˆ',
  '&clubs;': '♣',
  '&cong;': '≅',
  '&copy;': '©',
  '&crarr;': '↵',
  '&cup;': '∪',
  '&curren;': '¤',
  '&dagger;': '†',
  '&Dagger;': '‡',
  '&darr;': '↓',
  '&Dcaron;': 'Ď',
  '&dcaron;': 'ď',
  '&deg;': '°',
  '&Delta;': 'Δ',
  '&delta;': 'δ',
  '&diams;': '♦',
  '&divide;': '÷',
  '&dot;': '·',
  '&Dstrok;': 'Đ',
  '&dstrok;': 'đ',
  '&Eacute;': 'É',
  '&eacute;': 'é',
  '&Ebreve;': 'Ĕ',
  '&ebreve;': 'ĕ',
  '&Ecaron;': 'Ě',
  '&ecaron;': 'ě',
  '&Ecirc;': 'Ê',
  '&ecirc;': 'ê',
  '&Edot;': 'Ė',
  '&edot;': 'ė',
  '&Egrave;': 'È',
  '&egrave;': 'è',
  '&Emacr;': 'Ē',
  '&emacr;': 'ē',
  '&empty;': '∅',
  '&emsp;': ' ',
  '&ENG;': 'Ŋ',
  '&eng;': 'ŋ',
  '&ensp;': ' ',
  '&Eogon;': 'Ę',
  '&eogon;': 'ę',
  '&Epsilon;': 'Ε',
  '&epsilon;': 'ε',
  '&equiv;': '≡',
  '&Eta;': 'Η',
  '&eta;': 'η',
  '&ETH;': 'Ð',
  '&eth;': 'ð',
  '&Euml;': 'Ë',
  '&euml;': 'ë',
  '&euro;': '€',
  '&exist;': '∃',
  '&fnof;': 'ƒ',
  '&forall;': '∀',
  '&frac12;': '½',
  '&frac14;': '¼',
  '&frac34;': '¾',
  '&Gamma;': 'Γ',
  '&gamma;': 'γ',
  '&Gbreve;': 'Ğ',
  '&gbreve;': 'ğ',
  '&Gcedil;': 'Ģ',
  '&gcedil;': 'ģ',
  '&Gcirc;': 'Ĝ',
  '&gcirc;': 'ĝ',
  '&Gdot;': 'Ġ',
  '&gdot;': 'ġ',
  '&ge;': '≥',
  '&gt;': '>',
  '&harr;': '↔',
  '&Hcirc;': 'Ĥ',
  '&hcirc;': 'ĥ',
  '&hearts;': '♥',
  '&hellip;': '…',
  '&Hstrok;': 'Ħ',
  '&hstrok;': 'ħ',
  '&Iacute;': 'Í',
  '&iacute;': 'í',
  '&Ibreve;': 'Ĭ',
  '&ibreve;': 'ĭ',
  '&Icirc;': 'Î',
  '&icirc;': 'î',
  '&Idot;': 'İ',
  '&iexcl;': '¡',
  '&Igrave;': 'Ì',
  '&igrave;': 'ì',
  '&IJlig;': 'Ĳ',
  '&ijlig;': 'ĳ',
  '&Imacr;': 'Ī',
  '&imacr;': 'ī',
  '&imath;': 'ı',
  '&inodot;': 'ı',
  '&infin;': '∞',
  '&int;': '∫',
  '&Iogon;': 'Į',
  '&iogon;': 'į',
  '&Iota;': 'Ι',
  '&iota;': 'ι',
  '&iquest;': '¿',
  '&isin;': '∈',
  '&Itilde;': 'Ĩ',
  '&itilde;': 'ĩ',
  '&Iuml;': 'Ï',
  '&iuml;': 'ï',
  '&Jcirc;': 'Ĵ',
  '&jcirc;': 'ĵ',
  '&Kappa;': 'Κ',
  '&kappa;': 'κ',
  '&Kcedil;': 'Ķ',
  '&kcedil;': 'ķ',
  '&kgreen;': 'ĸ',
  '&Lacute;': 'Ĺ',
  '&lacute;': 'ĺ',
  '&Lambda;': 'Λ',
  '&lambda;': 'λ',
  '&laquo;': '«',
  '&larr;': '←',
  '&Lcaron;': 'Ľ',
  '&lcaron;': 'ľ',
  '&Lcedil;': 'Ļ',
  '&lcedil;': 'ļ',
  '&lceil;': '⌈',
  '&ldquo;': '“',
  '&le;': '≤',
  '&lfloor;': '⌊',
  '&Lmidot;': 'Ŀ',
  '&lmidot;': 'ŀ',
  '&lowast;': '∗',
  '&loz;': '◊',
  // '&lrm;':'Left-to-right mark',
  '&lsaquo;': '‹',
  '&lsquo;': '‘',
  '&Lstrok;': 'Ł',
  '&lstrok;': 'ł',
  '&lt;': '<',
  '&macr;': '¯',
  '&mdash;': '—',
  '&micro;': 'µ',
  '&minus;': '−',
  '&Mu;': 'Μ',
  '&mu;': 'μ',
  '&nabla;': '∇',
  '&Nacute;': 'Ń',
  '&nacute;': 'ń',
  '&napos;': 'ŉ',
  '&nbsp;': ' ',
  '&Ncaron;': 'Ň',
  '&ncaron;': 'ň',
  '&Ncedil;': 'Ņ',
  '&ncedil;': 'ņ',
  '&ndash;': '–',
  '&ne;': '≠',
  '&NewLine;': `
`,
  '&ni;': '∋',
  '&not;': '¬',
  '&notin;': '∉',
  '&nsub;': '⊄',
  '&Ntilde;': 'Ñ',
  '&ntilde;': 'ñ',
  '&Nu;': 'Ν',
  '&nu;': 'ν',
  '&Oacute;': 'Ó',
  '&oacute;': 'ó',
  '&Obreve;': 'Ŏ',
  '&obreve;': 'ŏ',
  '&Ocirc;': 'Ô',
  '&ocirc;': 'ô',
  '&Odblac;': 'Ő',
  '&odblac;': 'ő',
  '&OElig;': 'Œ',
  '&oelig;': 'œ',
  '&Ograve;': 'Ò',
  '&ograve;': 'ò',
  '&oline;': '‾',
  '&Omacr;': 'Ō',
  '&omacr;': 'ō',
  '&Omega;': 'Ω',
  '&omega;': 'ω',
  '&Omicron;': 'Ο',
  '&omicron;': 'ο',
  '&oplus;': '⊕',
  '&or;': '∨',
  '&ordf;': 'ª',
  '&ordm;': 'º',
  '&Oslash;': 'Ø',
  '&oslash;': 'ø',
  '&Otilde;': 'Õ',
  '&otilde;': 'õ',
  '&otimes;': '⊗',
  '&Ouml;': 'Ö',
  '&ouml;': 'ö',
  '&para;': '¶',
  '&part;': '∂',
  '&permil;': '‰',
  '&perp;': '⊥',
  '&Phi;': 'Φ',
  '&phi;': 'φ',
  '&Pi;': 'Π',
  '&pi;': 'π',
  '&piv;': 'ϖ',
  '&plusmn;': '±',
  '&pound;': '£',
  '&prime;': '′',
  '&Prime;': '″',
  '&prod;': '∏',
  '&prop;': '∝',
  '&Psi;': 'Ψ',
  '&psi;': 'ψ',
  '&quot;': '"',
  '&Racute;': 'Ŕ',
  '&racute;': 'ŕ',
  '&radic;': '√',
  '&raquo;': '»',
  '&rarr;': '→',
  '&Rcaron;': 'Ř',
  '&rcaron;': 'ř',
  '&Rcedil;': 'Ŗ',
  '&rcedil;': 'ŗ',
  '&rceil;': '⌉',
  '&rdquo;': '”',
  '&reg;': '®',
  '&rfloor;': '⌋',
  '&Rho;': 'Ρ',
  '&rho;': 'ρ',
  // '&rlm;':'Right-to-left mark',
  '&rsaquo;': '›',
  '&rsquo;': '’',
  '&Sacute;': 'Ś',
  '&sacute;': 'ś',
  '&sbquo;': '‚',
  '&Scaron;': 'Š',
  '&scaron;': 'š',
  '&Scedil;': 'Ş',
  '&scedil;': 'ş',
  '&Scirc;': 'Ŝ',
  '&scirc;': 'ŝ',
  '&sdot;': '⋅',
  '&sect;': '§',
  // '&shy;':'­Soft hyphen',
  '&Sigma;': 'Σ',
  '&sigma;': 'σ',
  '&sigmaf;': 'ς',
  '&sim;': '∼',
  '&spades;': '♠',
  '&sub;': '⊂',
  '&sube;': '⊆',
  '&sum;': '∑',
  '&sup;': '⊃',
  '&sup1;': '¹',
  '&sup2;': '²',
  '&sup3;': '³',
  '&supe;': '⊇',
  '&szlig;': 'ß',
  '&Tab;': 'Tab',
  '&Tau;': 'Τ',
  '&tau;': 'τ',
  '&Tcaron;': 'Ť',
  '&tcaron;': 'ť',
  '&Tcedil;': 'Ţ',
  '&tcedil;': 'ţ',
  '&there4;': '∴',
  '&Theta;': 'Θ',
  '&theta;': 'θ',
  '&thetasym;': 'ϑ',
  '&thinsp;': ' ', // 'Thin space',
  '&THORN;': 'Þ',
  '&thorn;': 'þ',
  '&tilde;': '˜',
  '&times;': '×',
  '&trade;': '™',
  '&Tstrok;': 'Ŧ',
  '&tstrok;': 'ŧ',
  '&Uacute;': 'Ú',
  '&uacute;': 'ú',
  '&uarr;': '↑',
  '&Ubreve;': 'Ŭ',
  '&ubreve;': 'ŭ',
  '&Ucirc;': 'Û',
  '&ucirc;': 'û',
  '&Udblac;': 'Ű',
  '&udblac;': 'ű',
  '&Ugrave;': 'Ù',
  '&ugrave;': 'ù',
  '&Umacr;': 'Ū',
  '&umacr;': 'ū',
  '&uml;': '¨',
  '&Uogon;': 'Ų',
  '&uogon;': 'ų',
  '&upsih;': 'ϒ',
  '&Upsilon;': 'Υ',
  '&upsilon;': 'υ',
  '&Uring;': 'Ů',
  '&uring;': 'ů',
  '&Utilde;': 'Ũ',
  '&utilde;': 'ũ',
  '&Uuml;': 'Ü',
  '&uuml;': 'ü',
  '&Wcirc;': 'Ŵ',
  '&wcirc;': 'ŵ',
  '&Xi;': 'Ξ',
  '&xi;': 'ξ',
  '&Yacute;': 'Ý',
  '&yacute;': 'ý',
  '&Ycirc;': 'Ŷ',
  '&ycirc;': 'ŷ',
  '&yen;': '¥',
  '&yuml;': 'ÿ',
  '&Yuml;': 'Ÿ',
  '&Zeta;': 'Ζ',
  '&zeta;': 'ζ',
  '&zwj;': '', // 'Zero width joiner',
  '&zwnj;': '', // 'Zero width non-joiner',
};

export const RichText = ({ children, containerTag, containerClassName }: RichTextProps) => {
  let content = children ?? '';
  Object.keys(htmlEntities).forEach((k) => {
    const rex = RegExp(`${k}`, 'gm');
    content = content.replace(rex, htmlEntities[k]);
  });

  const objChild = parse(content);

  return React.createElement(containerTag ?? 'div', {
    className: classNames(styles.container, containerClassName ?? ''),
    dangerouslySetInnerHTML: { __html: objChild.outerHTML },
  });
};
