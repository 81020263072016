import { Icon, IconsEnum } from '../../../Icons';
import {
  setIsOpenJumioModalValue,
  setIsOpenLoginModalValue,
  setIsOpenLotteryModalValue,
  setIsOpenSimpleInfoValue,
  setIsOpenUnregistrationModal,
  setIsOpenUploadDocumentModal,
} from 'features/modal/modalSlice';

import { AnchorLink } from '../../../anchorLink';
import { ThemeLogo } from 'components/themeLogo';
import { ThemeLogoType } from '../../../themeLogo';
import { setAutoEsclusionString } from 'features/dashboard/dashboardSlice';
import styles from './ModalHeader.module.scss';
import { useAppDispatch } from 'lib/centralStore';
import { useRouter } from 'next/router';

export type ModalHeaderProps = {
  logoBrand?: ThemeLogoType;
  label?: string;
  modalType:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'delete-message-modal'
    | 'fullheight'
    | 'bonus-modal'
    | 'modalTutorial';
  modalCategory: string;
  customRoute?: string | undefined;
  onClose?: () => void;
  headerClass?: string;
  showCloseButton?: boolean;
};

export const ModalHeader = ({
  logoBrand,
  label,
  modalType,
  modalCategory,
  customRoute,
  onClose,
  headerClass,
  showCloseButton = true,
}: ModalHeaderProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const handleCloseModal = (type: string, customRoute?: string) => {
    if (type === 'lottery-modal') {
      router.push(router.pathname.replace('/[slug]', ''));
      dispatch(setIsOpenLotteryModalValue(false));
    }
    if (type === 'login-modal') {
      dispatch(setIsOpenLoginModalValue(false));
      dispatch(setAutoEsclusionString(undefined));
    }
    if (type === 'simple-modal') {
      dispatch(setIsOpenSimpleInfoValue({ condition: true, setModal: false }));
    }
    if (type === 'jumio-modal') {
      dispatch(setIsOpenJumioModalValue(false));
    }
    if (type === 'registration-modal') {
      if (!customRoute) {
        dispatch(setIsOpenUnregistrationModal(true));
      } else {
        dispatch(setIsOpenUnregistrationModal(false));
        router.push(customRoute!);
      }
    }
    if (type === 'unregistration-modal') {
      dispatch(setIsOpenUnregistrationModal(false));
    }
    if (type === 'upload-document') {
      dispatch(setIsOpenUploadDocumentModal(false));
    }
    if (type === 'reset-password') {
      router.push('/');
    }

    onClose && onClose();
  };
  return (
    <div className={`${styles.container} ${headerClass && headerClass} ${styles[modalType]}`}>
      {logoBrand && (
        <AnchorLink href="/" className={styles.logoContainer}>
          <ThemeLogo
            alt={logoBrand?.alt}
            src={logoBrand?.src}
            title={logoBrand?.title}
            fill
            srcDarkMode={logoBrand?.srcDarkMode}
          />
        </AnchorLink>
      )}
      {label && <span className={styles.label}>{label}</span>}

      {showCloseButton && (
        <button type="button" className={styles.button} onClick={() => handleCloseModal(modalCategory, customRoute)}>
          <Icon iconId={IconsEnum.CLOSE} className={styles.icon} color="var(--color-on-bg-primary)" />
        </button>
      )}
    </div>
  );
};
