import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'lib/centralStore';

const _self = (state: RootState) => state.dashboard;

export const selectStringAutoesclusion = createSelector([_self], ({ stringAutoesclusion }) => stringAutoesclusion);
export const selectInjectDigitalAssistantCompleted = createSelector(
  [_self],
  ({ injectDigitalAssistantCompleted }) => injectDigitalAssistantCompleted
);

export const selectAvatar = createSelector([_self], ({ avatar }) => avatar);
export const selectSaldo = createSelector([_self], ({ saldo }) => saldo);
export const selectBonusGold = createSelector([_self], ({ bonusGold }) => bonusGold);
export const selectSaldoPrelevabile = createSelector([_self], ({ saldoPrelevabile }) => saldoPrelevabile);
export const selectUpdateWalletTimestamp = createSelector(
  [_self],
  ({ updateWalletTimestamp }) => updateWalletTimestamp
);
