import { isInternalLink, isMatch, isTruthy } from '../../utility/functions';

import type { Url } from 'components/mobileTabNavigation';
import { AppFragment } from 'enums/path-fragment';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import styles from './AnchorLink.module.scss';

export type AnchorLinkProps = Omit<LinkProps, 'href'> & {
  id?: string;
  rel?: string;
  href?: Url;
  size?: 'large' | 'medium' | 'small';
  target?: '_blank' | '_self' | '_parent' | '_top';
  children?: React.ReactNode;
  download?: string;
  isButton?: boolean;
  className?: string;
  visualizationType?: 'primary' | 'secondary' | 'tertiary' | 'sideMenuUserPrimary' | 'sideMenuUserTertiary';
};

export const AnchorLink = (props: AnchorLinkProps) => {
  const { push, asPath } = useRouter();
  const { rel, href, size, target, isButton, visualizationType, className, download, children, onClick, ...oth } =
    props ?? {};

  const { wRel, wHref, wTarger, wInternal, wDownload, wClassName } = useMemo(() => {
    const wHref = typeof href === 'string' ? href : href?.toString();
    const wInternal = isInternalLink(wHref);
    const wRel = wInternal ? rel : 'noreferrer';
    const wTarger = target ?? (wInternal ? '_self' : '_blank');
    const wDownload = isTruthy(download);

    const wClassName = [
      size ? styles[size] : '',
      isButton ? styles.buttonContainer : '',
      className ?? '',
      visualizationType ? styles[visualizationType] : '',
    ].join(' ');

    return {
      wRel,
      wHref,
      wTarger,
      wInternal,
      wDownload,
      wClassName,
    };
  }, [rel, href, target, download, isButton, visualizationType, size, className]);

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const isGoingToLogin = isMatch(asPath, `\/${AppFragment.Login}\/`);
    const isLotteryPage = isMatch(asPath, `\/${AppFragment.Lotterie}\/`);
    // SOME LOTTERY PAGE MODIFIES DOCUMENT ATTRIBUTE, NAVIGATION OCCURS VIA HTTP REQUEST IN THOSE CASES
    // TODO : ANALISYS AND PROPER FIX
    const isInternal = isLotteryPage || isGoingToLogin ? false : wInternal;
    if (wHref && isInternal) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (typeof onClick === 'function') {
      onClick(event);
    }
    if (wHref && isInternal) {
      push(wHref);
    }
  };

  return (
    <a
      rel={wRel}
      href={wHref}
      target={wTarger}
      download={wDownload}
      className={wClassName}
      {...oth}
      onClick={onClickHandler}
    >
      {children}
    </a>
  );
};
