import { Icon, IconsEnum } from 'components/Icons';
import { Spinner } from 'components/spinner';
import { InputHTMLAttributes, useState } from 'react';
import { Controller, FieldValues, Path, UseControllerProps } from 'react-hook-form';
import { isControlledInput } from '../utils';
import styles from './TextInput.module.scss';

export type TextInputProps = {
  label?: string;
  value?: string;
  defaultValue?: string;
  hasMarginRight?: boolean;
  hasMarginBottom?: boolean;
  hasMarginTop?: boolean;
  hasMarginLeft?: boolean;
  errorMessage?: string;
  trailingIcon?: IconsEnum;
  className?: string;
  ref?: React.Ref<HTMLInputElement>;
  tolltipString?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export type ControlledTextInputProps<T extends FieldValues = FieldValues, K extends Path<T> = Path<T>> = Omit<
  TextInputProps,
  'errorMessage'
> &
  UseControllerProps<T, K>;

export function TextInput<T extends FieldValues = FieldValues>(props: ControlledTextInputProps<T>): JSX.Element;
export function TextInput(props: TextInputProps): JSX.Element;
export function TextInput(props: TextInputProps | ControlledTextInputProps): JSX.Element {
  const { name, hasMarginBottom, hasMarginLeft, hasMarginRight, hasMarginTop, tolltipString, ...defaultProps } = props;

  const [tooltipActive, setTooltipActive] = useState(false);

  if (isControlledInput<ControlledTextInputProps>(props)) {
    return (
      <Controller
        key={`${props.name}_controller_`}
        control={props.control}
        name={props.name}
        rules={props.rules}
        defaultValue={props.value}
        render={({ field, fieldState: { error } }) => {
          const { control } = props;
          const currentError = props.control?._formState.errors[props.name];

          return (
            <div
              className={`${styles.container}
            ${hasMarginTop! ? styles.containerMarginTop : ''}
            ${hasMarginRight! ? styles.containerMarginRight : ''}
            ${hasMarginBottom! ? styles.containerMarginBottom : ''}
            ${hasMarginLeft! ? styles.containerMarginLeft : ''} 
            ${props.className}
          `}
            >
              {props.label && (
                <label htmlFor={props.name} className={styles.label}>
                  {props.label}
                </label>
              )}
              <div className={styles.inputWrapper}>
                <input
                  {...defaultProps}
                  id={props.name}
                  name={props.name}
                  key={`${props.name}_${props.type}`}
                  type={props.type || 'text'}
                  className={`${styles.input} ${error && error?.type !== 'loading' && styles.inputError}`}
                  ref={field.ref}
                  value={field.value || ''}
                  onChange={(e) => {
                    props.onChange && props.onChange(e);
                    field.onChange(e);
                  }}
                  onBlur={(e) => {
                    if (tolltipString) {
                      setTooltipActive(false);
                    }
                    props.onBlur && props.onBlur(e);
                    field.onBlur();
                  }}
                  onFocus={(_e) => {
                    if (tolltipString) {
                      setTooltipActive(true);
                    }
                  }}
                />
                {error && error.type === 'loading' && <Spinner className={styles.loading} />}
                {error && error.type !== 'loading' && (
                  <div className={styles.trailingIcon}>
                    <Icon iconId={props.trailingIcon ?? IconsEnum.CLOSE_CIRCLE} className={`${styles.icon}`} />
                  </div>
                )}
              </div>
              {tolltipString && (
                <div className={`${styles.tooltip} ${tooltipActive ? styles.focused : ''}`}>{tolltipString}</div>
              )}
              {error && (
                <span className={styles.errorMessage}>
                  {error?.message ?? (error?.types && Object.keys(error?.types!).map((key) => error?.types![key]))}
                </span>
              )}
            </div>
          );
        }}
      />
    );
  }

  return (
    <div
      className={`${styles.container}
    ${hasMarginTop! ? styles.containerMarginTop : ''}
    ${hasMarginRight! ? styles.containerMarginRight : ''}
    ${hasMarginBottom! ? styles.containerMarginBottom : ''}
    ${hasMarginLeft! ? styles.containerMarginLeft : ''} 
    ${props.className}`}
    >
      {props.label && (
        <label htmlFor={props.label} className={styles.label}>
          {props.label}
        </label>
      )}
      <div className={styles.inputWrapper}>
        <input
          id={props.label}
          type={props.type ?? 'text'}
          placeholder={props.placeholder}
          className={`${styles.input} ${props.errorMessage && styles.inputError}`}
          onChange={(e) => props.onChange && props.onChange(e)}
          value={props.value || ''}
          disabled={props.disabled || false}
          onBlur={(_e) => {
            if (tolltipString) {
              setTooltipActive(false);
            }
          }}
          onFocus={(_e) => {
            if (tolltipString) {
              setTooltipActive(true);
            }
          }}
        />
        {props.errorMessage && (
          <div className={styles.trailingIcon}>
            <Icon iconId={props.trailingIcon ?? IconsEnum.CLOSE_CIRCLE} className={`${styles.icon}`} />
          </div>
        )}
      </div>
      {tolltipString && (
        <div className={`${styles.tooltip} ${tooltipActive ? styles.focused : ''}`}>{tolltipString}</div>
      )}
      {props?.errorMessage && <span className={styles.errorMessage}>{props?.errorMessage}&nbsp;</span>}
    </div>
  );
}
