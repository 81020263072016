import Image, { ImageProps } from 'next/image';

import styles from './ThemeLogo.module.scss';
import { useMemo } from 'react';
import { useTheme } from 'next-themes';

export type ThemeLogoProps = ImageProps & {
  srcDarkMode?: string;
  redirectUrlLogo?: string;
};

export const ThemeLogo = ({ alt, src, width, fill, srcDarkMode, ...props }: ThemeLogoProps) => {
  const { theme } = useTheme();

  const { imgSrc, dynProps } = useMemo(() => {
    const dynProps: Record<string, any> = {};
    if (width) {
      dynProps['width'] = width;
    } else if (fill) {
      dynProps['fill'] = fill;
    }

    const imgSrc = theme === 'dark' ? srcDarkMode : src;

    return {
      imgSrc,
      dynProps,
    };
  }, [fill, src, srcDarkMode, theme, width]);

  if (imgSrc) {
    return <Image {...props} {...dynProps} alt={alt ?? ''} src={imgSrc} className={styles.logo} />;
  }

  // eslint-disable-next-line @next/next/no-img-element
  return <img {...props} {...dynProps} alt={alt} className={styles.logo} />;
};

export const Fallback = () => {
  // eslint-disable-next-line @next/next/no-img-element
  return <img className={styles.logo} src="" alt="" />;
};
