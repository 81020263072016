import Image from 'next/image';
import styles from './BackgroundImage.module.scss';

export type BackgroundImageProps = {
  containerClassName?: string;
  imageClassName?: string;
  src?: string;
};

export const BackgroundImage = ({
  containerClassName,
  imageClassName,
  src = 'https://www.datocms-assets.com/76629/1695215603-1674481760-background.svg',
}: BackgroundImageProps) => {
  return (
    <span className={`${styles.container} ${containerClassName ? containerClassName : ''}`}>
      <Image fill src={src} alt={'Logo SNAI'} className={`${styles.logo} ${imageClassName ? imageClassName : ''}`} />
    </span>
  );
};
