import type { ThemeLogoProps } from './ThemeLogo';
import dynamic from 'next/dynamic';
import { Fallback as loading } from './ThemeLogo';
export type { ThemeLogoProps as ThemeLogoType };

export const ThemeLogo = dynamic<ThemeLogoProps>(
  () => import('components/themeLogo/ThemeLogo').then((mod) => mod.ThemeLogo),
  {
    ssr: false,
    loading,
  }
);
